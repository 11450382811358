import React, { useEffect, useRef, useState, useCallback } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout/Layout";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import CTA from "@v4/talend/src/components/cta/Cta";
import { HeroWithIcon } from "../../components/hero/HeroWithIcon";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { getPrefixedPathByPageType, getLangPrefixedPath } from "@v4/utils/pathPrefix";
import { seoCategoryHubPageTranslations } from "../../translations/seoCategoryHubPage";
import { commonTranslations } from "@v4/gatsby-theme-talend/src/translations/common";
import { useLocation } from "@reach/router";
import queryString from "query-string";
import PlusSVG from "@v4/gatsby-theme-talend/src/assets/svg/plus-symbol--circle.inline.svg";
import MinusSVG from "@v4/gatsby-theme-talend/src/assets/svg/minus-symbol--circle.inline.svg";

// Styling
import * as SEOCategoryHubStyles from "./SEOCategoryHubPage.module.css";
import classNames from "classnames/bind";
import getObjectSubset from "../../utils/getObjectSubset";
const cx = classNames.bind(SEOCategoryHubStyles);

export const query = graphql`
    query($locale: String!, $id: String!) {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        category: contentfulSeoCategory(id: { eq: $id }) {
            id
            title
            metaFields {
                ...meta
            }
            description
            heroSubHeading
            slug
            icon {
                # fluid (maxWidth: 100, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            iconWhite {
                # fluid (maxWidth: 100, transformations: "c_fit")
                public_id
                width
                height
                format
            }
            iconAltText
            heroImage {
                # fluid (maxWidth: 774)
                public_id
                width
                height
                format
            }
            heroImageAltText
            subCategories {
                id
                title
                description
                buttonText
                images {
                    # fixed (width: 780, height: 588, transformations: "c_fill")
                    public_id
                    width
                    height
                    format
                }
                links {
                    ...PageLink
                }
            }
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

export default function SEOCategoryHubPage({
    data: { contentfulMenu, category, footerNav, footerSocials, footerSubMenu },
    pageContext: { locale, relatedArticlesByCategory },
}) {
    const categorySlug = getPrefixedPathByPageType(category.slug, "ContentfulSeoCategory");

    const footerData = {
        footerNav,
        footerSocials,
        footerSubMenu,
    };

    // Meta values for the category page.
    const metaValues = category.metaFields ?? {};

    const translations = { ...commonTranslations[locale], ...seoCategoryHubPageTranslations[locale] };

    // Set up breadcrumbs object
    let crumbList = {
        [translations.knowledgeCenter]: "/knowledge-center/",
        [category.title]: null,
    };

    // Get location info
    const location = useLocation();

    // Define the query var from location
    const query = location.search
        ? queryString.parse(location.search, { arrayFormat: "separator", arrayFormatSeparator: "|" })
        : {};

    const stateVariables = [];

    // Carve up a list of the query variables to look for: `section0`, `section1`, etc.
    category.subCategories.map((subCat, index) => (stateVariables[index] = `section${index}`));

    // Filter out the filter state vars from the query strings
    const urlState = getObjectSubset(stateVariables, query);

    const SEOSubCategory = ({ id, links, title, buttonText, images, description, index }) => {
        const relatedArticles = relatedArticlesByCategory?.[id];

        // First render ?
        const isFirstRender = useRef(true);

        // Listing of sub-category links, initially hidden behind an accordion.
        const SubCategoryList = () => {
            // Define an identifier
            const itemName = `section${index}`;

            // Store the state of the accordion feature
            const [accordionIsOpen, setAccordionIsOpen] = useState(false);

            // Flag for whether or not the accordion/list is animating
            let isAnimating = useRef(false);

            // Create ref for "return to top" feature.
            const toggleListEl = useRef(null);

            // Callback func to handle tail of accordion opening animation
            const transEnd = useCallback((event) => {
                // Reset height to "auto" to handle any interior content shifts
                event.target.style.height = "auto";

                // Remove callback so elements are free to flow when needed
                event.target.removeEventListener("transitionend", transEnd);

                // End animation, change flag
                isAnimating.current = false;
            }, []);

            // Accordion Function
            const handleToggle = useCallback(
                (e, refEl) => {
                    // Get the wrapper element
                    const listWrapper = refEl.current;

                    if (isAnimating.current === false) {
                        // Animation starting, change flag
                        isAnimating.current = true;

                        // If the accordion is open, close it by transitioning from an explicit height down to 0
                        if (accordionIsOpen) {
                            // Closing the filter --
                            // Define initial starting height
                            listWrapper.style.height = `${listWrapper.firstChild.scrollHeight}px`;

                            // Closing: At the next frame, un-set the height (CSS sets to 0)
                            requestAnimationFrame(() => {
                                listWrapper.style.height = null;

                                // Completed animation, update flag
                                isAnimating.current = false;
                            });
                        } else {
                            // Opening the filter --
                            // Define height value which transitions from 0
                            listWrapper.style.height = `${listWrapper.firstChild.scrollHeight}px`;

                            // Assign event listener
                            listWrapper.addEventListener("transitionend", transEnd);
                        }

                        // Update state to match the accordion
                        setAccordionIsOpen(!accordionIsOpen);

                        // Upate the query
                        if (accordionIsOpen) {
                            // instead of showing 'section0=false', just totally remove the k/v pair
                            delete query[itemName];
                        } else {
                            // add 'section0=true'
                            query[itemName] = !accordionIsOpen;
                        }

                        // Format the query using the queryString lib, and prefix with a ? if present
                        let formattedQuery = queryString.stringify(query, {
                            arrayFormat: "separator",
                            arrayFormatSeparator: "|",
                        });

                        formattedQuery = formattedQuery ? `?${formattedQuery}` : "";

                        // Update the URL and browser history with the formatted query
                        window.history.replaceState(
                            window.history.state,
                            document.title,
                            `${location.pathname}${formattedQuery}`
                        );
                    }
                },
                [accordionIsOpen, itemName, transEnd]
            );

            // If the accordion state is present in query string open accordion on first render
            useEffect(() => {
                if (isFirstRender.current && urlState[itemName] === "true") {
                    isFirstRender.current = false;
                    setAccordionIsOpen(true);
                    handleToggle(null, toggleListEl);
                    return;
                }
            }, [itemName, handleToggle]);

            // Button for toggle, with "show all" / "show fewer" labels
            const toggleButton = (
                <button
                    className={cx("showAllButton")}
                    onClick={(e) => {
                        handleToggle(e, toggleListEl);
                    }}
                >
                    {accordionIsOpen ? (
                        <>
                            <span>{translations.showLess}</span>
                            <MinusSVG className={cx("showAllButtonMinus")} />
                        </>
                    ) : (
                        <>
                            <span>{translations.showMore}</span>
                            <PlusSVG className={cx("showAllButtonPlus")} />
                        </>
                    )}
                </button>
            );

            return (
                <div className={cx("subCategoryAccordion")}>
                    {relatedArticles ? (
                        <>
                            <div className={cx("subCategoryLinks", "subCategoryToggleList")} ref={toggleListEl}>
                                <ul>
                                    {relatedArticles?.map((el, i) => (
                                        <li key={i}>
                                            <Link to={getPrefixedPathByPageType(el.slug, el.__typename)}>
                                                {el.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {toggleButton}
                        </>
                    ) : null}
                </div>
            );
        };

        return (
            <div className={cx("subCategory", "mb-4", `index${index}`)}>
                <div className={cx("subCategoryInner")}>
                    <div className={cx("subCategoryCopy")}>
                        <h2 className={cx("subCategoryTitle")}>{title}</h2>
                        <p className={cx("subCategoryDescription")}>{description}</p>
                        {links?.[0] && (
                            <CTA
                                url={getPrefixedPathByPageType(links?.[0].slug, links?.[0].__typename)}
                                variant="Tertiary"
                                title={buttonText ?? translations.readMore}
                            />
                        )}
                    </div>
                    {images?.[0] ? (
                        <div className={cx("subCategoryMedia")}>
                            <Image
                                placeholderStyle={{ filter: `blur(10px)` }}
                                image={images?.[0]}
                                style={{ width: 350, maxWidth: "100%", height: 269, display: "block" }}
                                alt={"test"}
                            />
                        </div>
                    ) : null}
                </div>
                <SubCategoryList />
            </div>
        );
    };

    return (
        <Layout headerMenu={contentfulMenu} footerMenu={footerData} notificationBar={false} pageStyleProps={{}}>
            <SEO
                title={metaValues.metaTitle || category.title}
                pathname={getLangPrefixedPath(categorySlug, locale)}
                description={metaValues.metaDescription || category.description}
                canonicalUrl={metaValues.canonicalUrl}
                canonicalUrlOverride={metaValues.canonicalUrlOverride}
                robots={metaValues.metaRobot}
                bingBot={metaValues.bingBot}
                googleBot={metaValues.googleBot}
                image={metaValues.metaImage}
                languages={["All"]}
                type={`Article`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            {category && (
                <HeroWithIcon
                    title={category.title}
                    description={category.heroSubHeading || category.description}
                    gradientType={"purpleGradient"}
                    iconImage={category.iconWhite[0] || category.icon[0]}
                    iconImageStyle={{
                        width: "56px",
                        height: "auto",
                    }}
                    iconImageAltText={category.iconAltText}
                    heroImage={category.heroImage?.[0]}
                    heroImageStyle={{
                        width: "387px",
                        height: "auto",
                    }}
                    heroImageAltText={category.heroImageAltText}
                />
            )}
            <section className="container px-2 mb-4">
                <Breadcrumbs crumbList={crumbList} />
                {category.subCategories.map((subCat, index) => (
                    <SEOSubCategory {...subCat} key={subCat.id} index={index} />
                ))}
            </section>
        </Layout>
    );
}
