export const seoCategoryHubPageTranslations = {
    en: {
        knowledgeCenter: "Knowledge center",
    },
    "en-GB": {
        knowledgeCenter: "Knowledge centre",
    },
    fr: {
        knowledgeCenter: "Centre de connaissances",
    },
    de: {
        knowledgeCenter: "Knowledge center",
    },
    it: {
        knowledgeCenter: "Knowledge center",
    },
    ja: {
        knowledgeCenter: "Knowledge center",
    },
};
